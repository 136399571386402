import { OrderData } from "client/components/ChaptersOrderLayout/ChaptersOrderLayout.partials";
import { rideBrokerT, withTranslationReady } from "common/i18n/withTranslationReady";
import { Trans, WithTranslation } from "react-i18next";
import React, { useState } from "react";
import TextLinkAsButton from "uiLibrary/legacy/TextLinkAsButton/TextLinkAsButton";
import {
  ConfirmationModal,
  ConfirmationModalType
} from "uiLibrary/v2/components/ConfirmationModal/ConfirmationModal";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "client/components/OnboardingStepCard/OnboardingStepCard";
import { DataLayer, OrderEventProductType } from "lib/services/GoogleTagManager/GoogleTagManager";

interface CompleteApplicationCardProps {
  status: OnboardingStepCardStatus;
  order: OrderData<any> | null | undefined;
  createOrder: () => Promise<OrderData<any>>;
  cancelOrder: (orderId: string) => Promise<void>;
  goToOrderForm: (order: OrderData<any>) => void;
  cardIndex: number;
}

export const CompleteApplicationCard = withTranslationReady(["generic"])(
  ({
    t,
    status,
    order,
    createOrder,
    cancelOrder,
    goToOrderForm,
    cardIndex
  }: CompleteApplicationCardProps & WithTranslation) => {
    const [showModal, setShowModal] = useState(false);

    const handleBeginApplication = async () => {
      const createdOrder = await createOrder();

      DataLayer.OrderStarted({
        ordered_products: {
          [OrderEventProductType.RIDE_BROKER]: {
            product: OrderEventProductType.RIDE_BROKER,
            quantity: 1,
            price: 0
          }
        }
      });

      goToOrderForm(createdOrder);
    };

    const handleContinueApplication = () => {
      if (order) {
        goToOrderForm(order);
      }
    };

    const handleCancelApplication = async () => {
      if (order?.id) {
        await cancelOrder(order.id);
      }
      setShowModal(false);
    };

    return (
      <OnboardingStepCard
        data-testid="complete-application-card"
        number={cardIndex}
        title={t(rideBrokerT("generic:low-tax-broker-overview.complete-application.title"))}
        status={status}>
        <div className="ltb-overview__content-box">
          <div className="ltb-overview__content-title">
            {t(rideBrokerT("generic:low-tax-broker-overview.complete-application.content-title"))}
          </div>
          <div className="ltb-overview__content">
            <Trans
              i18nKey={rideBrokerT(
                "generic:low-tax-broker-overview.complete-application.content-description"
              )}
              components={{
                LearnMore: (
                  <a
                    href={"https://www.ride.capital/services/broker?utm_source=app&utm_medium=app"}
                    target={"_blank"}
                    rel="noopener noreferrer">
                    {}
                  </a>
                )
              }}
            />
          </div>
        </div>
        <div className="ltb-overview__buttons">
          {order ? (
            <>
              <button
                data-testid="continue-application-button"
                className="ltb-overview__primary-action-btn"
                onClick={handleContinueApplication}>
                {t("generic:low-tax-broker-overview.complete-application.continue-application")}
              </button>
              <TextLinkAsButton
                data-testid="restart-application-button"
                className="ltb-overview__action-text-link"
                onClick={() => setShowModal(true)}>
                {t("generic:low-tax-broker-overview.complete-application.restart-application")}
              </TextLinkAsButton>
            </>
          ) : (
            <button
              data-testid="begin-application-button"
              className="ltb-overview__primary-action-btn"
              disabled={true}
              style={{ backgroundColor: "#fafafa", cursor: "not-allowed" }}
              onClick={handleBeginApplication}>
              {t("generic:low-tax-broker-overview.complete-application.begin-application")}
            </button>
          )}
        </div>
        <ConfirmationModal
          title={t("generic:confirm-action-modal-delete.title")}
          message={t("generic:confirm-action-modal-delete.messages.delete-order")}
          type={ConfirmationModalType.DANGER}
          confirmLabel={t(
            "generic:low-tax-broker-overview.complete-application.restart-application"
          )}
          visible={showModal}
          onConfirm={handleCancelApplication}
          onClose={() => setShowModal(false)}
        />
      </OnboardingStepCard>
    );
  }
);
