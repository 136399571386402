import { GetMyGmbHsList } from "components/client/MyGmbHs/Queries";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

const EmptyCompany = "0";

interface CompanySelectionParams {
  companyId?: string;
  companyPage?: string;
}

export const useCompanyNavigation = () => {
  const history = useHistory();

  const myGmbHsRequest = GetMyGmbHsList();
  const companies = myGmbHsRequest.data?.companies;

  const params = useParams<{ id: string; mode: string }>();
  const selectedCompanyPage = params?.mode;

  const selectedCompanyId = params?.id || companies?.[0]?.id;
  const selectedCompany = companies?.find((company) => company.id === selectedCompanyId);

  const getCompanyPath = React.useCallback(
    ({ companyId, companyPage }: CompanySelectionParams) => {
      const targetCompanyId = companyId || selectedCompanyId || EmptyCompany;
      const targetPage = companyPage || selectedCompanyPage;
      return `/client/entities/${targetCompanyId}/${targetPage}`;
    },
    [selectedCompanyId, selectedCompanyPage]
  );

  const navigateToCompany = (params: CompanySelectionParams) => {
    const page = getCompanyPath(params);
    history.replace(page);
  };

  return {
    companies,
    selectedCompany,
    navigateToCompany,
    getCompanyPath
  };
};

export const useCompanyNavigationParams = () => {
  const { id, mode } = useParams<{ id: string; mode: string }>();
  const isCompanySelected = id !== EmptyCompany;

  return { id, mode, isCompanySelected };
};
