import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Navbar } from "react-bootstrap";
import { RideLogoBlack } from "sharedComponents/RideLogo/RideLogo";
import ClientNavigationMenuItems from "../NavigationMenuItems/NavigationMenuItems";

const moduleName = "ride-navigation";

const MenuButton = ({
  active,
  setActive
}: {
  active: boolean;
  setActive: (active: boolean) => void;
}) => {
  return (
    <button
      onClick={() => setActive(!active)}
      className={`${moduleName}__topbar-hamburger p-0 hamburger hamburger--spin ${
        active ? "is-active" : ""
      }`}
      data-testid="hamburger-button">
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  );
};

const NavigationTopbarComponent = ({ t }: WithTranslation) => {
  const [showMenu, setShowMenu] = React.useState(false);

  return (
    <>
      <div className={`${moduleName}__topbar-placeholder`}></div>
      <nav className={`${moduleName}__topbar`} data-testid="wealth-navigation-topbar">
        <div
          className={`${moduleName}__topbar-header d-flex flex-row align-items-center justify-content-between border-bottom px-3`}>
          <Navbar.Brand>
            <RideLogoBlack />
          </Navbar.Brand>
          <MenuButton active={showMenu} setActive={setShowMenu} />
        </div>
        {showMenu && (
          <div className={`${moduleName}__topbar-menu`}>
            <ClientNavigationMenuItems onClick={() => setShowMenu(false)} />
            <div className="mb-5"></div>
          </div>
        )}
      </nav>
    </>
  );
};

export const NavigationTopbar = withTranslation(["header", "client", "generic"])(
  NavigationTopbarComponent
);
