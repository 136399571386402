import { withTranslation, WithTranslation } from "react-i18next";
import { FullPersonDetailsQuery_getPersonDetails_user } from "components/nominee/NomineePerson/graphql/FullPersonDetailsQuery";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { formatDateTime } from "common/formatters";
import { ValueView } from "components/common/layout/ValueView";
import { getCurrentTimestamp } from "common/dateProvider";

interface Props extends WithTranslation {
  user: FullPersonDetailsQuery_getPersonDetails_user;
  extendSignupTokenValidity: (userId: string) => Promise<void>;
}

const SignupTokenSection = ({ t, user, extendSignupTokenValidity }: Props) => {
  const now = getCurrentTimestamp();
  const isExpired = new Date(user.signupTokenExpiryAt).valueOf() < now.valueOf();

  const onClick = async () => {
    await extendSignupTokenValidity(user.id);
  };

  return (
    <>
      <h2>{t("nominee-dashboard:signup-token-section.title")}</h2>

      <Row>
        <Col xs="auto">
          <ValueView
            t={t}
            testId="signup-token-validity"
            name="nominee-dashboard:signup-token-section.validity"
            value={formatDateTime(user.signupTokenExpiryAt)}
          />
        </Col>
        {isExpired && (
          <Col>
            <Button data-testid="extend-signup-token-validity-btn" onClick={onClick}>
              {t("nominee-dashboard:signup-token-section.extend")}
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

export default withTranslation(["generic", "nominee-dashboard"])(SignupTokenSection);
