import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { ReactComponent as CompanyIcon } from "uiLibrary/Icons/CompanyIcon.svg";

export interface CompanyMenuItem {
  id: string;
  name: string;
}

interface Props {
  company: CompanyMenuItem;
}

const CompanySelectorDropdownItem = ({ company, t }: Props & WithTranslation) => {
  const companyName = company.name || t("client.my-gmbhs");

  return (
    <div className="d-flex align-items-center py-2" title={companyName}>
      <CompanyIcon />
      <div className="text-truncate flex-grow-1 ml-3">{companyName}</div>
    </div>
  );
};

export default withTranslation(["header", "client", "generic"])(CompanySelectorDropdownItem);
