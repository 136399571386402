import { useParams } from "react-router-dom";

export const useCompanyPageHeader = () => {
  const params = useParams() as { id: string; mode: string };

  switch (params?.mode) {
    case "home":
      return "generic:home-tab";
    case "general-info":
      return "generic:master-data";
    case "invest":
      return "broker-v2:invest";
    case "admin-service":
      return "generic:admin-service";
    case "securities-booking":
      return "generic:wepa-service";
    case "lei":
      return "generic:lei-number";
    case "real-estate":
      return "generic:real-estate";
    case "banking":
      return "generic:banking";
    default:
      return "generic:home-tab";
  }
};
