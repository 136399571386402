import { CompanyType, LegalForm } from "global-query-types";
import { companyDetailsByCompanyId_companyById } from "../../graphql/companyDetailsByCompanyId";
import { getCurrentTimestampWithoutLocalOffset } from "common/dateProvider";
import { OptionItem } from "../../../generic/Select";
import { companyName } from "lib/formatters/companyFormatter";

export interface IEditUserValues {
  companyId: string;
  name: string;
  legalForm: LegalForm;
  capitalContribution: number;
  companyValue: number;
  percentageOwnership: number;
  ownerId: string;
  ownerSince: any;
  street: string;
  streetLine2?: string | null;
  city: string;
  postalCode: string;
  type: string;
  tradeTaxFree: string;
  registerCourt: string;
  registration: string;
  taxNumber: string;
  vatNumber: string;
}

export const getCompanyShare = (companyData: companyDetailsByCompanyId_companyById) => {
  const companyShare: number =
    companyData.group?.memberships?.find((m) => !!m.share)?.share?.share ?? 0;
  return companyShare;
};

export const getOwnerId = (companyData: companyDetailsByCompanyId_companyById) => {
  const ownerCompanyId =
    companyData &&
    companyData.group.memberships?.find((m) => !!m.share)?.share?.membership.company?.id;

  return ownerCompanyId ?? "";
};

export const getOwnerSince = (companyData: companyDetailsByCompanyId_companyById) => {
  const ownerSince: Date = companyData.group?.memberships?.find((m) => !!m.share)?.share?.startsAt;
  return ownerSince ? new Date(ownerSince) : getCurrentTimestampWithoutLocalOffset();
};

export const getCompanyOwner = (companyData: companyDetailsByCompanyId_companyById) => {
  const ownerCompany =
    companyData && companyData.group.memberships?.find((m) => !!m.share)?.share?.membership.company;
  const ownerPersonally =
    companyData && companyData.group.memberships?.find((m) => !!m.share)?.share?.membership.person;
  return ownerCompany
    ? companyName(ownerCompany)
    : `${ownerPersonally?.firstName} ${ownerPersonally?.lastName}`;
};

export const getCompanyTypesTranslation = (availableCompanyTypes, t) => {
  const userCompanyTypesTranslation = {
    [CompanyType.Operating]: t("user-company:operating-company"),
    [CompanyType.PersonalHolding]: t("user-company:personal-holding-company"),
    [CompanyType.ClientEntity]: t("user-company:personal-holding-company"),
    [CompanyType.Spv]: t("user-company:special-purpose-vehicle")
  };

  return (
    availableCompanyTypes?.__type?.enumValues?.map(
      (item) =>
        ({
          title: userCompanyTypesTranslation[item.name] || "",
          value: item.name
        } as OptionItem)
    ) || []
  );
};
export const getInitialValues = (companyData: companyDetailsByCompanyId_companyById) => {
  const initialValues: IEditUserValues = {
    companyId: companyData.id,
    name: companyData.name,
    legalForm: companyData.legalForm,
    companyValue: companyData.companyValue ?? 0.0,
    percentageOwnership: getCompanyShare(companyData),
    ownerId: getOwnerId(companyData),
    ownerSince: getOwnerSince(companyData),
    capitalContribution: companyData.capitalContribution,
    street: companyData.businessAddress.street,
    streetLine2: companyData.businessAddress.streetLine2,
    city: companyData.businessAddress.city,
    postalCode: companyData.businessAddress.postalCode,
    type: companyData.type!,
    tradeTaxFree: companyData.tradeTaxFree === true ? "YES" : "NO",
    registerCourt: companyData.registerCourt || "",
    registration: companyData.registration || "",
    taxNumber: companyData.taxNumber || "",
    vatNumber: companyData.vatNumber || ""
  };

  return initialValues;
};
