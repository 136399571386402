import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

const moduleName = "ride-navigation";

interface Props {
  path: string;
  MenuIcon: React.ElementType;
  title: string;
}

export const NavigationMenuItem = ({ path, title, MenuIcon }: Props) => {
  const match = useRouteMatch(path);

  return (
    <Link to={path} className={`${moduleName}__menu-item ${match?.isExact ? "active" : ""}`}>
      <MenuIcon />
      <span className="ml-2">{title}</span>
    </Link>
  );
};
