import React, { useState } from "react";
import { AddGmbHModalContainer } from "client/components/AddGmbHModal/AddGmbHModalContainer";
import { useCompanyNavigation } from "../hooks/useCompanyNavigation";
import CompanySelector, { CompanyMenuItem } from "./CompanySelector";
import { getCompanyNameOrDefault } from "lib/formatters/companyFormatter";

const useCompanyMenuItems = () => {
  const { companies, selectedCompany, navigateToCompany } = useCompanyNavigation();
  const [companyMenuItems, setCompanyMenuItems] = useState<CompanyMenuItem[]>([]);
  const [selectedCompanyMenuItem, setSelectedCompanyMenuItem] = useState<CompanyMenuItem>();

  React.useEffect(() => {
    const items = (companies ?? []).map((c) => {
      return {
        id: c.id,
        name: getCompanyNameOrDefault(c.name)
      };
    });

    setCompanyMenuItems(items);
    setSelectedCompanyMenuItem(items.find((c) => c.id === selectedCompany?.id));
  }, [companies, selectedCompany]);

  return {
    companyMenuItems,
    selectedCompanyMenuItem,
    navigateToCompany
  };
};

export const CompanySelectorContainer = () => {
  const [showAddGmbHModal, setShowAddGmbHModal] = useState(false);
  const { companyMenuItems, selectedCompanyMenuItem, navigateToCompany } = useCompanyMenuItems();

  return (
    <div>
      {showAddGmbHModal && <AddGmbHModalContainer onClose={() => setShowAddGmbHModal(false)} />}
      <CompanySelector
        selectedCompany={selectedCompanyMenuItem}
        companies={companyMenuItems}
        onSelectCompany={(companyId) => navigateToCompany({ companyId })}
        onAddCompany={() => setShowAddGmbHModal(true)}
      />
    </div>
  );
};
