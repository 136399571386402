import { Col, Row } from "react-bootstrap";
import React from "react";
import ClientTags from "components/common/ClientTags/ClientTags";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { CompanySelectorContainer } from "components/wealthNavigation/CompanySelector/CompanySelectorContainer";
import { useCompanyPageHeader } from "components/wealthNavigation/hooks/useCompanyPageHeader";
import { useTranslation } from "react-i18next";
import GmbHGrundungServiceBadge from "client/components/GmbHGrundungServiceBadge/GmbHGrundungServiceBadge";

interface IProps {
  gmbh: GmbHDetails | undefined | null;
  showClientTags?: boolean;
}

export const NavigationCompanyHeader = ({ gmbh, showClientTags = false }: IProps) => {
  const moduleName = "wealth-navigation-company-header";

  const pageHeader = useCompanyPageHeader();
  const { t } = useTranslation();

  return (
    <header
      className={`${moduleName} d-flex flex-column align-items-center justify-content-center`}
      data-testid="wealth-navigation-company-header">
      <div
        className={`${moduleName}__company-info d-flex flex-column-reverse flex-lg-row align-items-center`}>
        <div className={`${moduleName}__company-name flex-grow-1 d-flex align-items-center`}>
          <h3 data-testid="company-title" className={`${moduleName}__company-name__title m-0`}>
            {t(pageHeader)}
          </h3>
          {gmbh && (
            <div className={`${moduleName}__company-status ml-3`}>
              {gmbh.isInFoundation && (
                <GmbHGrundungServiceBadge status={gmbh.grundungServiceStatus} />
              )}
            </div>
          )}
        </div>
        <div className={`${moduleName}__company-selector-container pb-3 pt-lg-0`}>
          <CompanySelectorContainer />
        </div>
      </div>

      {showClientTags && gmbh && (
        <Row>
          <Col className={`${moduleName}__client-tags`}>
            <ClientTags client={gmbh.clientTagsInput()} />
          </Col>
        </Row>
      )}
    </header>
  );
};
