import React from "react";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Form, FormikProvider } from "formik";
import { useRideFormik } from "lib/hooks/useRideFormik";
import { CountrySelect } from "sharedComponents/CountrySelect/CountrySelect";
import { RideDatePicker } from "uiLibrary/v2/components/RideDatePicker/RideDatePicker";
import { RideDropdownSelect } from "uiLibrary/components/RideDropdownSelect/RideDropdownSelect";
import { proofOfIdentityTypeOptions } from "admin/components/OrderFilesTable/OrderFilesTable";
import { Step } from "lib/models/client/order/Step";
import * as Yup from "yup";
import { requiredString } from "lib/validation/requiredString";
import { getCurrentTimestamp } from "common/dateProvider";
import { StepComponentProps } from "../../../ChaptersOrderLayout.partials";
import { AdminServiceOrderData } from "../../../../../../lib/models/client/AdminService/AdminServiceOrderData";

const SelectIdDocumentTypeForAdminServiceStep = ({
  t,
  controls,
  saveData,
  order,
  currentStep
}: StepComponentProps<AdminServiceOrderData> & WithTranslation) => {
  const moduleName = "select-id-document-type-for-admin-service";

  let initialValues = {
    typeOfDocument: order.extra.documents?.passportOrID?.typeOfDocument ?? "",
    issuingCountry: order.extra.documents?.passportOrID?.issuingCountry ?? "",
    validThrough: order.extra.documents?.passportOrID?.validThrough ?? ""
  };

  const onSubmit = async (values) => {
    currentStep.complete();

    const idSteps = () => {
      return [
        currentStep,
        new Step({ key: "shareholder-id-file" }, currentStep.chapter),
        new Step({ key: "shareholder-id-file-back" }, currentStep.chapter)
      ];
    };

    const passportSteps = () => {
      return [currentStep, new Step({ key: "shareholder-passport-file" }, currentStep.chapter)];
    };

    if (
      order.extra.documents?.passportOrID &&
      values["typeOfDocument"] !== order.extra.documents.passportOrID?.typeOfDocument
    ) {
      order.extra.documents.passportOrID = {};
    }

    const proofOfIDFile = order.extra.documents?.passportOrID ?? {};

    if (values["typeOfDocument"] === "PASSPORT" && proofOfIDFile.typeOfDocument !== "PASSPORT") {
      currentStep.chapter.steps = passportSteps();
    }

    if (values["typeOfDocument"] === "ID" && proofOfIDFile.typeOfDocument !== "ID") {
      currentStep.chapter.steps = idSteps();
    }

    await saveData({
      ...order.extra,
      documents: {
        ...order.extra.documents,
        passportOrID: {
          ...order.extra.documents?.passportOrID,
          typeOfDocument: values["typeOfDocument"],
          issuingCountry: values["issuingCountry"],
          validThrough: values["validThrough"]
        }
      },
      ...currentStep.chapter.order.serialize()
    });
  };

  const SelectIdDocumentTypeSchema = (t) => {
    return Yup.object({
      typeOfDocument: requiredString(t),
      issuingCountry: requiredString(t),
      validThrough: Yup.string()
        .required(t("generic:validation-required"))
        .test(
          "validThrough",
          t("generic:validation-future-date"),
          (value) => new Date(value!) >= getCurrentTimestamp()
        )
    });
  };

  const formik = useRideFormik({
    initialValues,
    onSubmit,
    validationSchema: SelectIdDocumentTypeSchema(t)
  });

  return (
    <FormikProvider value={formik}>
      <Form className="subchapter-order-layout__form" data-testid={moduleName}>
        <div className="select-id-document-type subchapter-order-layout__scrollable">
          <RideDropdownSelect
            name="typeOfDocument"
            options={proofOfIdentityTypeOptions(t)}
            label={t("generic:low-tax-broker-order.file-section.document-type")}
            className={`${moduleName}__input`}
          />

          <CountrySelect
            name="issuingCountry"
            label={t("generic:low-tax-broker-order.file-section.issuing-country")}
            className={`${moduleName}__input`}
          />
          <RideDatePicker
            label={t("generic:low-tax-broker-order.file-section.valid-until")}
            name="validThrough"
          />
        </div>
        {controls}
      </Form>
    </FormikProvider>
  );
};

export default withTranslationReady(["generic"])(SelectIdDocumentTypeForAdminServiceStep);
