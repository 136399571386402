import { TFunction } from "i18next";
import {
  getCurrentTimestampWithoutLocalOffset,
  withoutLocalOffset
} from "../../../../common/dateProvider";
import { dateFieldFor } from "../../../../components/generic/commonFields";
import { QueryResult } from "react-apollo";
import { RequestFeedbackInfo } from "../../../../components/generic/RequestFeedbackHandler";
import { FormikProvider, useFormik } from "formik";
import RequestFeedbackForm from "../../../../sharedComponents/FormControls/RequestFeedbackForm/RequestFeedbackForm";
import { MutationResult } from "@apollo/react-common";
import React from "react";

export const CompanyEditDateFieldForm = ({
  t,
  fieldName,
  value,
  onSave,
  requests
}: {
  t: TFunction;
  fieldName: string;
  value: string;
  onSave: (value: string) => Promise<void>;
  requests: (MutationResult | QueryResult | RequestFeedbackInfo)[];
}) => {
  let selectedDate;

  try {
    selectedDate = new Date(Date.parse(value));
  } catch (err) {
    selectedDate = getCurrentTimestampWithoutLocalOffset();
  }

  if (isNaN(selectedDate?.getUTCDate?.())) {
    selectedDate = getCurrentTimestampWithoutLocalOffset();
  }

  const initialValues = {
    [fieldName]: selectedDate
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      if (!values[fieldName]) {
        return await onSave("");
      }

      const date = withoutLocalOffset(values[fieldName]);
      await onSave(date.toISOString());
    }
  });

  const maxDate = new Date(Date.parse("2030-12-12T00:00:00"));

  return (
    <div className={`company-edit-date-field-form ${fieldName}-date-edit`}>
      <FormikProvider value={formik}>
        <RequestFeedbackForm requests={requests} submitLabel={t("generic:save")}>
          {dateFieldFor(
            t,
            formik,
            formik.values,
            fieldName,
            t(`client:company.field.${fieldName}`),
            "dd/MM/yyyy",
            formik.values[fieldName],
            formik.values[fieldName],
            maxDate,
            "",
            false,
            [],
            { className: "ride-input__input" }
          )}
        </RequestFeedbackForm>
      </FormikProvider>
    </div>
  );
};
