import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import CompanySelectorDropdownItem from "./CompanySelectorItem";
import { AddIcon } from "sharedComponents/icons/add";

const moduleName = "company-selector";

const SelectorToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
  <div
    className={`${moduleName}__dropdown`}
    ref={ref}
    role="button"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}>
    {children}
  </div>
));

export interface CompanyMenuItem {
  id: string;
  name: string;
}

interface Props {
  selectedCompany?: CompanyMenuItem;
  companies: CompanyMenuItem[];
  onSelectCompany: (companyId: string) => void;
  onAddCompany: () => void;
}

const CompanySelector = ({
  selectedCompany,
  companies,
  onSelectCompany,
  onAddCompany,
  t
}: Props & WithTranslation) => {
  return (
    <Dropdown
      className={`${moduleName}__container`}
      data-testid="wealth-navigation-company-selector">
      <Dropdown.Toggle id="dropdown-custom-components" as={SelectorToggle}>
        <div className="d-flex flex-row">
          <div className="flex-grow-1">
            {selectedCompany ? (
              <CompanySelectorDropdownItem company={selectedCompany} />
            ) : (
              <>{t("client.my-gmbhs")}</>
            )}
          </div>
          <img src="/assets/uiLibrary/icons/chevron.svg" alt="⌄" />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100">
        {companies.map((company) => (
          <Dropdown.Item
            key={company.id}
            active={company.id === selectedCompany?.id}
            onClick={() => onSelectCompany(company.id)}>
            <CompanySelectorDropdownItem company={company} />
          </Dropdown.Item>
        ))}
        <Dropdown.Item onClick={onAddCompany}>
          <div className="d-flex align-items-center py-2">
            <AddIcon />
            <div className="flex-grow-1 ml-3">{t("generic:add-company")}</div>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default withTranslation(["header", "client", "generic"])(CompanySelector);
