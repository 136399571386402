import React from "react";
import { RideSectionCard } from "sharedComponents/RideSectionCard/RideSectionCard";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";
import {
  ButtonVariant,
  RideButtonDeprecated
} from "../../../sharedComponents/Buttons/RideButtonDeprecated/RideButtonDeprecated";
import moment from "moment";
import { getWepaBookings_getWepaBookings_bookings } from "../../../lib/api/queries/graphql/getWepaBookings";
import { TFunction } from "i18next";
import { getCurrentTimestamp } from "../../../common/dateProvider";
import { MOMENT_DATE_FORMAT } from "../../../lib/formatters/config";
import { useHistory } from "react-router-dom";
import {
  AlertType,
  BarVariant,
  RideAlertBar
} from "../../../uiLibrary/components/RideAlertBar/RideAlertBar";
import { Typography } from "../../../uiLibrary/designSystem/styles/Typography/Typography";

interface ConfirmAccountingYearProps extends WithTranslation {
  companyId: string;
  bookings: getWepaBookings_getWepaBookings_bookings[];
  onConfirmBooking: (bookingId: number) => void;
}

const ConfirmAccountingYear = ({ bookings, companyId, t }: ConfirmAccountingYearProps) => {
  const readyBookings = bookings.filter(({ dateTo }) =>
    moment(dateTo).isBefore(getCurrentTimestamp())
  );

  return (
    <>
      <RideSectionCard
        className="my-5"
        data-testid="confirm-accounting-year-header"
        title={t("client:company.confirm-accounting-year.title")}>
        {!readyBookings.length && <NoCompletedYearsInfo t={t} />}
        {readyBookings.map((booking, index) =>
          isCustomerFinal(booking) ? (
            <>
              <AlreadyConfirmedBanner key={booking.id} booking={booking} t={t} />
              {index + 1 === readyBookings.length && (
                <Typography
                  size={200}
                  category={"Paragraph"}
                  weight={"Light"}
                  className={"confirm-accounting-year-contact-ops"}>
                  <Trans
                    data-testid={"confirm-accounting-year-contact-ops"}
                    i18nKey={"client:company.confirm-accounting-year.information"}
                    components={{
                      EmailLink: (
                        <a data-testid="contact-email" href={"mailto:clientservices@ride.capital"}>
                          {"clientservices@ride.capital"}
                        </a>
                      )
                    }}></Trans>
                </Typography>
              )}
            </>
          ) : (
            <AccountingYearConfirmationContent
              t={t}
              companyId={companyId}
              key={booking.id}
              booking={booking}
            />
          )
        )}
      </RideSectionCard>
    </>
  );
};

interface IProps extends Pick<ConfirmAccountingYearProps, "onConfirmBooking" | "companyId"> {
  booking?: getWepaBookings_getWepaBookings_bookings;
  t: TFunction;
  showModal: (booking: getWepaBookings_getWepaBookings_bookings) => void;
  hideModal: () => void;
}

export const isCustomerFinal = (booking: getWepaBookings_getWepaBookings_bookings) =>
  booking.status === "customer_final" || booking.status === "partner_final";

const NoCompletedYearsInfo = ({ t }) => (
  <RideAlertBar
    data-testid="confirm-accounting-year-information"
    type={AlertType.INFO}
    message={t("client:company.confirm-accounting-year.no-bookings-info")}
  />
);

const AlreadyConfirmedBanner = ({ booking, t }: Pick<IProps, "booking" | "t">) => (
  <RideAlertBar
    type={AlertType.SUCCESS}
    className="confirm-accounting-year-banner"
    data-testid="confirm-accounting-year-information"
    message={t("client:company.confirm-accounting-year.confirmed", {
      dateTo: moment(booking?.dateTo).format("YYYY")
    })}
    barVariant={BarVariant.Rounded}
  />
);

const AccountingYearConfirmationContent = ({
  booking,
  t,
  companyId
}: Pick<IProps, "booking" | "t" | "companyId">) => {
  const { push } = useHistory();

  return (
    <div data-testid="confirm-accounting-year-bookings" key={booking?.id}>
      <div>{t("client:company.confirm-accounting-year.text")}</div>

      <div className="mt-3" data-testid="confirm-accounting-year-date">
        <RideBadgeStatus
          color={RideBadgeColorsEnum.WARNING}
          variant={RideBadgeVariantsEnum.SOLID}
          label={moment(booking?.dateTo).format(MOMENT_DATE_FORMAT)}
        />
      </div>

      <div className="confirm-accounting-year-info" data-testid="confirm-accounting-year-info">
        {t("client:company.confirm-accounting-year.infoText")}
      </div>

      <div className="mt-4 mb-4 d-flex justify-content-end">
        <RideButtonDeprecated
          variant={ButtonVariant.Primary}
          onClick={() => {
            if (!booking) return;
            push(`/client/entities/${companyId}/fiscal-years/${booking.fiscalYear}/confirmation`);
          }}
          data-testid="confirm-accounting-year-button">
          {t("client:company.confirm-accounting-year.confirm")}
        </RideButtonDeprecated>
      </div>
    </div>
  );
};

export default withTranslation(["generic", "client"])(ConfirmAccountingYear);
