import React from "react";
import EntityContainer from "../../components/Entity/EntityContainer";
import { MyGmbHsContainer } from "components/client/MyGmbHs/MyGmbHsContainer";
import { useCompanyNavigationParams } from "components/wealthNavigation/hooks/useCompanyNavigation";

export const EntityScene = () => {
  const { isCompanySelected } = useCompanyNavigationParams();

  if (!isCompanySelected) {
    return <MyGmbHsContainer />;
  }

  return <EntityContainer />;
};

export default EntityScene;
