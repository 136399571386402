import React from "react";
import { Container } from "react-bootstrap";
import RideFooter, { AppsVariant } from "sharedComponents/RideFooter/RideFooter";
import { FeatureFlag } from "../../FeatureFlags/FeatureFlag/FeatureFlag";
import { FeatureFlags } from "global-query-types";
import TwoFactorAuthenticationNotificationContainer from "auth/components/TwoFactorAuthenticationNotification/TwoFactorAuthenticationNotificationContainer";
import { FeatureFlagService } from "lib/services/FeatureFlagService/FeatureFlagService";
import ClientHeader from "client/components/ClientHeader/ClientHeader";
import { NavigationTopbar } from "components/wealthNavigation/NavigationTopbar/NavigationTopbar";
import { NavigationSidebar } from "components/wealthNavigation/NavigationSidebar/NavigationSidebar";

interface ClientLayoutProps {
  component: React.FC<any>;
}

export const ClientLayout = (props: ClientLayoutProps) => {
  const { component: Component } = props;

  const useWealthNavigation = FeatureFlagService.instance.isEnabled(FeatureFlags.WealthNavigation);

  if (useWealthNavigation) {
    return (
      <>
        <div className="d-block d-lg-none">
          <NavigationTopbar />
        </div>
        <div className="d-flex flex-row w-100 min-h-100">
          <div className="d-none d-lg-block">
            <NavigationSidebar />
          </div>
          <div className="w-100 flex-grow-1">
            <Container
              fluid
              className="p-0 flex-fill ride-body full-height"
              data-testid="client-page-layout">
              <Component {...props} />
            </Container>
            <RideFooter app={AppsVariant.Client} />
            <FeatureFlag name={FeatureFlags.TwoFactorAuthenticationSetup}>
              <TwoFactorAuthenticationNotificationContainer />
            </FeatureFlag>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <ClientHeader />
      <Container fluid className="p-0 flex-fill ride-body" data-testid="client-page-layout">
        <Component {...props} />
      </Container>
      <RideFooter app={AppsVariant.Client} />
      <FeatureFlag name={FeatureFlags.TwoFactorAuthenticationSetup}>
        <TwoFactorAuthenticationNotificationContainer />
      </FeatureFlag>
    </>
  );
};
