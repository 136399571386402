import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Navbar } from "react-bootstrap";
import { RideLogoBlack } from "sharedComponents/RideLogo/RideLogo";
import ClientNavigationMenuItems from "../NavigationMenuItems/NavigationMenuItems";

const moduleName = "ride-navigation";

const NavigationSidebarComponent = ({ t }: WithTranslation) => {
  return (
    <>
      <div className={`${moduleName}__sidebar-placeholder d-none d-md-block`}></div>
      <nav
        className={`${moduleName}__sidebar d-flex flex-column pb-5 border-right`}
        data-testid="wealth-navigation-sidebar">
        <div className={`${moduleName}__sidebar-logo ml-4 d-flex align-items-center`}>
          <Navbar.Brand>
            <RideLogoBlack />
          </Navbar.Brand>
        </div>
        <ClientNavigationMenuItems />
        <div className="my-5"></div>
      </nav>
    </>
  );
};
export const NavigationSidebar = withTranslation(["header", "client", "generic"])(
  NavigationSidebarComponent
);
