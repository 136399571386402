import React from "react";
import OnboardingStepCard, {
  OnboardingStepCardStatus
} from "../../OnboardingStepCard/OnboardingStepCard";
import { WithTranslation } from "react-i18next";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { Typography } from "uiLibrary/designSystem/styles/Typography/Typography";
import {
  RideButton,
  RideButtonSize,
  RideButtonVariant
} from "uiLibrary/designSystem/RideButton/RideButton";

export interface AdminServiceOrderFormTask {
  stepNumber: number;
  status: OnboardingStepCardStatus;
  upsertOrder: () => Promise<void>;
}

const AdminServiceOrderFormTask = ({
  t,
  stepNumber,
  status,
  upsertOrder
}: AdminServiceOrderFormTask & WithTranslation) => {
  return (
    <div>
      <OnboardingStepCard
        data-testid={"admin-service-order-form-task"}
        number={stepNumber}
        title={t("generic:admin-service-order-form-task.title")}
        status={status}>
        <div className="admin-service-order-form-task__body">
          <div className={"admin-service-order-form-task__body__information"}>
            <Typography category={"Paragraph"} size={200} weight={"Light"}>
              <div data-testid={"admin-service-order-form-task-description"}>
                {t(`generic:admin-service-order-form-task.description`)}
              </div>
            </Typography>
          </div>
          <RideButton
            style={{ backgroundColor: "#fafafa" }}
            data-testid={"admin-service-order-form-task-action-button"}
            className={"admin-service-order-form-task__body__action-button"}
            variant={RideButtonVariant.PRIMARY}
            size={RideButtonSize.BIG}
            label={t("generic:admin-service-order-form-task.to-as-onboarding")}
            disabled={true}
            onClick={upsertOrder}
          />
        </div>
      </OnboardingStepCard>
    </div>
  );
};

export default withTranslationReady(["generic"])(AdminServiceOrderFormTask);
