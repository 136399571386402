import { withTranslationReady } from "common/i18n/withTranslationReady";
import { ActionableTask, ActionableTaskOptions } from "common/types/ProcessStatesTypes";
import { TaskStatesEnum } from "global-query-types";
import React, { useState } from "react";
import { WithTranslation } from "react-i18next";
import { useFormik } from "formik";
import { ClientSelect, ClientSelectOption } from "client/components/ClientSelect/ClientSelect";
import { getCurrentTimestamp } from "../../../../../common/dateProvider";
import { TaskContainerWithFormik } from "../../../../../components/client/company/components/tasks/TaskContainer/TaskContainerWithFormik";
import { formatToQuarterAndYear } from "lib/dataAdapters/formatToQuarterAndYear/formatToQuarterAndYear";
import {
  RideBadgeColorsEnum,
  RideBadgeStatus,
  RideBadgeVariantsEnum
} from "uiLibrary/components/RideBadgeStatus";
import FiscalYearQuarterSelect from "sharedComponents/FiscalYearQuarterSelect/FiscalYearQuarterSelect";
import * as Yup from "yup";
import { LinkVariant, TextLink } from "../../../../../sharedComponents/TextLink/TextLink";

interface ChooseFiscalYearTaskProps extends WithTranslation {
  task: ActionableTask;
  onChange: (
    state: TaskStatesEnum,
    options?: ActionableTaskOptions,
    nextTaskToRenderRef?: string
  ) => Promise<void>;
}

const ChooseFiscalYearTask = ({ task, onChange, t }: ChooseFiscalYearTaskProps) => {
  const [fiscalYear, setFiscalYear] = useState<string | null>(null);
  const [fiscalYearQuarter, setFiscalYearQuarter] = useState<string | null>(null);
  const [isCustomFiscalYearSelectionVisible, setIsCustomFiscalYearSelectionVisible] =
    useState(false);
  const nextState = {
    COMPLETED: TaskStatesEnum.TO_BE_DONE,
    TO_BE_DONE: TaskStatesEnum.COMPLETED
  };

  const currentYear = getCurrentTimestamp().getUTCFullYear();
  const nextYear = currentYear + 1;

  const validateYear = (year: string): boolean => {
    return Number(year) >= 1900 && Number(year) <= nextYear;
  };
  const availableFiscalYears = [currentYear, nextYear];
  const fiscalYearOptions: ClientSelectOption[] = availableFiscalYears.map(
    (label) =>
      ({
        label,
        value: label
      } as unknown as ClientSelectOption)
  );

  const formik = useFormik({
    initialValues: { fiscalYearOption: "", fiscalYear: "" },
    validationSchema: Yup.object().shape({
      fiscalYear: Yup.number()
        .min(1900, t("generic:invalidFiscalYear"))
        .max(nextYear, t("generic:invalidFiscalYear"))
    }),
    onSubmit: async (values) => {
      const [day, month] = fiscalYearQuarter?.split(".") ?? ["01", "01"];
      await onChange(nextState[task.state], {
        type: "FiscalYear",
        data: {
          day: !fiscalYear && !fiscalYearQuarter ? "" : day,
          month: !fiscalYear && !fiscalYearQuarter ? "" : month,
          year: fiscalYear ?? values["fiscalYear"].toString()
        }
      });
    }
  });

  const isFiscalYearInputEmpty = (): boolean => {
    return formik.values["fiscalYear"] === "" || !validateYear(formik.values["fiscalYear"]);
  };

  const options = [
    { label: `01.01.${currentYear.toString()}`, value: "currentYear" },
    { label: `01.01.${nextYear.toString()}`, value: "upcomingYear" },
    { label: t("generic:anotherFiscalYearOption"), value: "custom" }
  ];

  const resetCustomFiscalYearInputs = () => {
    formik.values["fiscalYear"] = "";
    setFiscalYearQuarter(null);
  };

  const shouldRenderTheSelectedFiscalYear = fiscalYearQuarter && !isFiscalYearInputEmpty();
  const youtubeExplanatoryVideoLink = t(
    "company-founding-steps:tasks.chooseFiscalYear.extraDetailsLink"
  );

  const TaskToBeDoneView = (
    <>
      <div className="choose-fiscal-year-task__extra-details">
        <span data-testid="extra-details">
          {t("company-founding-steps:tasks.chooseFiscalYear.extraDetails")}
        </span>
        <TextLink
          variant={LinkVariant.primary}
          to={youtubeExplanatoryVideoLink}
          data-testid="extra-details-link">
          {youtubeExplanatoryVideoLink}
        </TextLink>
      </div>
      <ClientSelect
        name="fiscalYearOption"
        changeHandler={(selectedValue) => {
          const enableCustomFiscalYearSelection = selectedValue === "custom";
          setIsCustomFiscalYearSelectionVisible(enableCustomFiscalYearSelection);

          if (selectedValue === "currentYear") {
            setFiscalYear(currentYear.toString());
            resetCustomFiscalYearInputs();
          } else if (selectedValue === "upcomingYear") {
            setFiscalYear((currentYear + 1).toString());
            resetCustomFiscalYearInputs();
          } else if (enableCustomFiscalYearSelection) {
            setFiscalYear(null);
          }
        }}
        testIdPrefix="fiscal-year-options"
        className="choose-fiscal-year-task__fiscal-year-options"
        options={options}
      />
      {isCustomFiscalYearSelectionVisible && (
        <>
          <div className="choose-fiscal-year-task__fiscal-year-select-year-container">
            <ClientSelect
              name={"fiscalYear"}
              className="choose-fiscal-year-task__fiscal-year-options"
              options={fiscalYearOptions}
              label={t("company-founding-steps:tasks.chooseFiscalYear.insertFiscalYear")}
              testIdPrefix="fiscal-year"
              changeHandler={(year) => {
                formik.values["fiscalYear"] = year;
                setFiscalYear(year.toString());
              }}
            />
          </div>
          <div className="choose-fiscal-year-task__fiscal-year-select-quarter-container">
            <FiscalYearQuarterSelect
              formik={formik}
              changeHandler={(date) => {
                setFiscalYearQuarter(date);
              }}
            />
          </div>
          {shouldRenderTheSelectedFiscalYear && (
            <SelectedFiscalYear
              year={formik.values["fiscalYear"]}
              month={fiscalYearQuarter?.split(".")[1]}
            />
          )}
        </>
      )}
    </>
  );

  const TaskCompletedView = (
    <SelectedFiscalYear month={task.options?.data?.month} year={task.options?.data?.year} />
  );
  const customFiscalYearCanBeSubmitted =
    !isFiscalYearInputEmpty() && fiscalYearQuarter && fiscalYear;
  const fiscalYearCanBeSubmitted =
    isFiscalYearInputEmpty() && !fiscalYearQuarter && fiscalYear !== null;

  const submitButtonShouldBeDisabled =
    task.state === TaskStatesEnum.TO_BE_DONE &&
    !customFiscalYearCanBeSubmitted &&
    !fiscalYearCanBeSubmitted;

  return (
    <TaskContainerWithFormik
      formik={formik}
      testid="choose-company-fiscal-year"
      translationKey={task.ref}
      disabled={submitButtonShouldBeDisabled}
      task={task}>
      {task.state === TaskStatesEnum.COMPLETED ? TaskCompletedView : TaskToBeDoneView}
    </TaskContainerWithFormik>
  );
};

const SelectedFiscalYear = ({
  month,
  year
}: {
  month: string | undefined;
  year: string | undefined;
}) => {
  const label = month && year ? formatToQuarterAndYear(month, year) : "---";

  return (
    <div
      data-testid="selected-fiscal-year"
      className="choose-fiscal-year-task__selected-fiscal-year">
      <RideBadgeStatus
        color={RideBadgeColorsEnum.DARK}
        variant={RideBadgeVariantsEnum.OUTLINED}
        label={label}
      />
    </div>
  );
};

export default withTranslationReady(["company-founding-steps", "generic"])(ChooseFiscalYearTask);
