import React, { PropsWithChildren } from "react";
import { Loader } from "client/components/Loader/Loader";

export enum ButtonVariant {
  Primary = "primary",
  PrimaryPastel = "primary-pastel",
  Secondary = "secondary",
  Danger = "danger",
  Grey = "grey"
}

export enum ButtonSize {
  Big = "big",
  Medium = "medium",
  Small = "small",
  ExtraSmall = "extraSmall"
}

export enum ButtonWidth {
  FIT_CONTENT = "fit-content",
  FULL = "full"
}

export enum ButtonHeight {
  NORMAL = "normal",
  LARGE = "large"
}

export enum ButtonRadius {
  Round = "round",
  Soft = "soft",
  Medium = "medium"
}

export enum ButtonIntensity {
  Strong = "strong",
  Soft = "soft"
}

export enum ButtonTextVariant {
  Legacy = "legacy",
  V2 = "v2",
  V2Medium = "v2-medium",
  V2Light = "v2-light"
}

export interface ButtonProps
  extends PropsWithChildren<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  > {
  variant?: ButtonVariant;
  radius?: ButtonRadius;
  size?: ButtonSize;
  width?: ButtonWidth;
  height?: ButtonHeight;
  intensity?: ButtonIntensity;
  textVariant?: ButtonTextVariant;
  isLoading?: boolean;
  isLoaderActive?: boolean;
  openExternalUrl?: boolean;
  href?: string;
  hasIcon?: boolean;
  darkMode?: boolean;
  "data-testid"?: string;
  target?: string;
  onClick?: (e) => void;
  labelFor?: string;
  keepModalOpened?: boolean;
}

/** @deprecated */
export const RideButtonDeprecated = ({
  variant = ButtonVariant.Secondary,
  radius = ButtonRadius.Round,
  size = ButtonSize.Medium,
  width = ButtonWidth.FIT_CONTENT,
  height = ButtonHeight.NORMAL,
  intensity = ButtonIntensity.Strong,
  textVariant = ButtonTextVariant.Legacy,
  isLoading = false,
  isLoaderActive = false,
  openExternalUrl,
  href,
  hasIcon = false,
  darkMode = false,
  className,
  children,
  target,
  labelFor,
  keepModalOpened = false,
  ...rest
}: ButtonProps) => {
  const modeClass = darkMode ? "-dark" : "";
  const intensityClass = intensity === ButtonIntensity.Soft ? "--soft" : "";
  const defaultButtonClassName = `button button--${width} button--${height} button--${variant}${modeClass}${intensityClass} button--${size} button--${variant}${modeClass}--${
    hasIcon ? "with-icon" : "without-icon"
  } ${textVariant === ButtonTextVariant.Legacy ? "" : `button--text-${textVariant}`} ${
    className ? className : ""
  } button--${size}-${radius}-radius`;

  const DefaultButton = (
    <button
      className={defaultButtonClassName}
      data-testid={rest["data-testid"] ?? "ride-button-default"}
      {...rest}>
      {children}
    </button>
  );
  const LoadingButton = (
    <button
      className={`button button--loading${modeClass} button--${size} ${className ? className : ""}`}
      {...rest}
      data-testid={rest["data-testid"] ?? "ride-button-loading"}
      disabled>
      <div className="button__loading-indicator-container">
        <div className="button__loading-indicator" />
      </div>
    </button>
  );
  const LoadingButtonV2 = (
    <button
      className={`button button--with-loader${modeClass}${intensityClass} button--${size} button--${width} button--${height} button--${size}-${radius}-radius ${
        className ? className : ""
      }`}
      {...rest}
      data-testid={rest["data-testid"] ?? "ride-button-with-loader"}
      disabled>
      <Loader />
    </button>
  );
  const LinkVariantButton = (
    <a
      href={href}
      data-testid={rest["data-testid"] ?? "ride-button-link-variant"}
      target="_blank"
      rel="noopener noreferrer"
      className={`${defaultButtonClassName} button--link-type`}
      onClick={rest["onClick"]}>
      {children}
    </a>
  );
  const LabelVariantButton = (
    <label
      className={`${defaultButtonClassName} button--label-type`}
      data-testid={rest["data-testid"] ?? "ride-button-default"}
      htmlFor={labelFor ?? ""}
      {...(rest as any)}>
      {children}
    </label>
  );

  if (isLoading) {
    return LoadingButton;
  }

  if (isLoaderActive) {
    return LoadingButtonV2;
  }

  if (openExternalUrl) {
    return LinkVariantButton;
  }

  if (labelFor) {
    return LabelVariantButton;
  }

  return DefaultButton;
};
