import React from "react";
import { GmbHDetails } from "lib/models/client/GmbHDetails";
import { WithTranslation } from "react-i18next";
import { CompanyTabbedNavigationValue } from "../CompanyTabbedNavigation/CompanyTabbedNavigation";
import { CompanyHeaderSection } from "client/components/CompanyHeaderSection/CompanyHeaderSection";
import { CompanyById_companyById } from "lib/api/queries/graphql/CompanyById";
import { useHistory, useLocation } from "react-router-dom";
import { withTranslationReady } from "common/i18n/withTranslationReady";
import { StorageConfig } from "components/client/company/components/GetStorageConfig";
import WepaSection from "client/components/WepaSection/WepaSection";
import { getWepaBookings_getWepaBookings_bookings } from "lib/api/queries/graphql/getWepaBookings";
import { BankType, FeatureFlags } from "global-query-types";
import { ExecutionResult } from "graphql";
import { CreateAdminServiceAddOn } from "components/client/company/graphql/CreateAdminServiceAddOn";
import { AdminServiceViewSwitcher, BankingTabSwitcher, LeiTabSwitcher } from "./Entity.partials";
import MasterData from "../MasterData/MasterData";
import { GetCompaniesWithASContractStatus_companies } from "../../../lib/api/queries/graphql/GetCompaniesWithASContractStatus";
import moment from "moment/moment";
import { FeatureFlagService } from "../../../lib/services/FeatureFlagService/FeatureFlagService";
import localStore from "common/LocalStore";
import { GetFormerClientAssignments_getFormerClientAssignments_items } from "../../../lib/api/queries/graphql/GetFormerClientAssignments";
import { LowTaxBrokerViewSwitcherContainer } from "../LowTaxBrokerViewSwitcher/LowTaxBrokerViewSwitcherContainer";
import { HomeTabContainer } from "./HomeTab/HomeTabContainer";
import {
  UpsertOrder_upsertOrder,
  UpsertOrderVariables
} from "../../../lib/api/mutations/graphql/UpsertOrder";
import { RealEstateTab } from "../RealEstateTab/RealEstateTab";
import { FeatureFlag } from "sharedComponents/FeatureFlags/FeatureFlag/FeatureFlag";

interface IProps extends WithTranslation {
  goBackLink: string | undefined;
  company: CompanyById_companyById;
  wepaBookings: getWepaBookings_getWepaBookings_bookings[];
  onConfirmBooking: (bookingId: number) => void;
  cancelAdminService: (id: string) => Promise<void>;
  storageConfig: StorageConfig | undefined;
  refetchCompany: () => Promise<void>;
  viewMode?: CompanyTabbedNavigationValue;
  createAdminServiceAddOn: () => Promise<ExecutionResult<CreateAdminServiceAddOn>>;
  upsertBankAccount: (vars: {
    bankProvider: BankType;
    companyId: string;
    iban?: string;
    name?: string;
  }) => Promise<void>;
  companiesWithASContractStatus: GetCompaniesWithASContractStatus_companies[];
  formerClientAssignments: GetFormerClientAssignments_getFormerClientAssignments_items[];
  upsertOrder: (order: UpsertOrderVariables) => Promise<UpsertOrder_upsertOrder | null | undefined>;
}

const Entity = ({
  t,
  company,
  cancelAdminService,
  refetchCompany,
  storageConfig,
  goBackLink,
  viewMode,
  wepaBookings,
  onConfirmBooking,
  createAdminServiceAddOn,
  upsertBankAccount,
  companiesWithASContractStatus,
  formerClientAssignments,
  upsertOrder
}: IProps) => {
  const { hash } = useLocation();
  const history = useHistory();
  const gmbh = new GmbHDetails(company, refetchCompany);

  const contracts = storageConfig?.company.basicStructure.find((a) => a.ref === "contracts");
  const adminServiceStructure = storageConfig?.company.adminServiceStructure ?? [];
  const storageConfigWithContracts = contracts
    ? [contracts, ...adminServiceStructure]
    : adminServiceStructure;
  const wepaStructure = storageConfig?.company.wepaStructure ?? [];

  setTimeout(() => {
    if (hash) {
      document.querySelector(hash)?.scrollIntoView();
    }
  }, 100);

  const changeView = (value) => {
    history.push(`/client/entities/${company.id}/${value}`);
  };

  const basicStructure = storageConfig?.company.basicStructure ?? [];

  let companyThatNeedsMigration = companiesWithASContractStatus.find(
    (company) =>
      company.asUpgradeRequiredFrom &&
      new Date(company.asUpgradeRequiredFrom).getTime() < moment.now() &&
      company.customerSignedAdminServiceContract !== true
  );

  if (
    companyThatNeedsMigration &&
    FeatureFlagService.instance.isEnabled(FeatureFlags.GRO777MigrateAdminServicePackage) &&
    (!localStore.getSessionValue("as-migrate-flow-snoozed") ||
      companyThatNeedsMigration.asUpgradeMandatory)
  ) {
    history.push(`/client/entities/${companyThatNeedsMigration.id}/admin-service/migrate`);
  }

  const showCompanyHeaderSection = !FeatureFlagService.instance.isEnabled(
    FeatureFlags.WealthNavigation
  );

  return (
    <>
      {showCompanyHeaderSection && (
        <CompanyHeaderSection
          gmbh={gmbh}
          viewMode={viewMode}
          goBackLink={goBackLink}
          handleViewModeChange={changeView}
        />
      )}
      {viewMode === CompanyTabbedNavigationValue.HOME && <HomeTabContainer gmbh={gmbh} />}
      {viewMode === CompanyTabbedNavigationValue.PRIMARY_DATA && (
        <MasterData gmbh={gmbh} storageConfig={basicStructure} />
      )}
      {viewMode === CompanyTabbedNavigationValue.ADMIN_SERVICE && (
        <AdminServiceViewSwitcher
          createAdminServiceAddOn={createAdminServiceAddOn}
          cancelAdminService={cancelAdminService}
          gmbh={gmbh}
          storageConfig={storageConfigWithContracts}
          refetchCompany={refetchCompany}
          formerClientAssignments={formerClientAssignments}
          t={t}
        />
      )}
      {viewMode === CompanyTabbedNavigationValue.WEPA && (
        <WepaSection
          gmbh={gmbh}
          bookings={wepaBookings}
          upsertOrder={upsertOrder}
          wepaStructure={wepaStructure}
          onConfirmBooking={onConfirmBooking}
          formerClientAssignments={formerClientAssignments}
        />
      )}
      {viewMode === CompanyTabbedNavigationValue.INVEST && (
        <LowTaxBrokerViewSwitcherContainer gmbh={gmbh} brokerageAccounts={gmbh.brokerageAccounts} />
      )}
      {viewMode === CompanyTabbedNavigationValue.BANKING && (
        <BankingTabSwitcher
          company={company}
          t={t}
          gmbh={gmbh}
          upsertBankAccount={upsertBankAccount}
          refetchCompany={refetchCompany}
        />
      )}
      {viewMode === CompanyTabbedNavigationValue.LEI_NUMBER && <LeiTabSwitcher company={company} />}
      {viewMode === CompanyTabbedNavigationValue.REAL_ESTATE && (
        <FeatureFlag name={FeatureFlags.RE52AddRealEstateSection}>
          <RealEstateTab />
        </FeatureFlag>
      )}
    </>
  );
};

export default withTranslationReady(["generic", "client"])(Entity);
